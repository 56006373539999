@mixin activities-tab-component($breakpoint, $input-container-padding-right: 10px, $input-container-margin-bottom: 20px)
    overflow-y: hidden
    &--errors
        position: absolute
        width: calc(100% - 22px)
        z-index: 11
        @media screen and (max-width: $breakpoint)
            position: fixed
            bottom: 20px
            width: calc(100% - 30px)
    &--journals-container
        z-index: 10
        padding-top: 3px
        overflow-y: auto

        &_with-initial-input-compensation
            margin-bottom: 65px // initial margin-bottom, will be increased by stimulus when opening ckeditor
            @media screen and (max-width: $breakpoint)
                margin-bottom: -16px

        &_with-input-compensation
            margin-bottom: 180px
            @media screen and (max-width: $breakpoint)
                margin-bottom: -16px
                
    &--input-container
        z-index: 10
        border-radius: var(--borderRadius-medium)
        border: none
        padding-right: 16px
        margin-bottom: $input-container-margin-bottom
        @media screen and (min-width: $breakpoint)
            position: absolute
            min-height: 60px
            bottom: 0
            left: 0
            right: 0
            border-radius: 0px
            border-top: var(--borderWidth-thin, 1px) solid var(--borderColor-default)
            padding-right: $input-container-padding-right
            margin-bottom: 0px

        &_sort-desc
            @media screen and (max-width: $breakpoint)
                order: -1

.work-packages-activities-tab-index-component
    @include activities-tab-component($breakpoint-xl)

.work-packages-activities-tab-index-component--within-notification-center
    .work-packages-activities-tab-index-component
        @include activities-tab-component($breakpoint-lg, 15px)

.work-packages-activities-tab-index-component--within-split-screen
    .work-packages-activities-tab-index-component
        @include activities-tab-component($breakpoint-sm, 10px, 10px)